<template>
  <section>
    <!-- private area selector -->
    <private-area-selector></private-area-selector>

    <div class="pc-container">
      <div class="item-2">
        <profile-customer-sidebar></profile-customer-sidebar>
      </div>
      <div class="item">
        <router-view></router-view>
      </div>
    </div>
  </section>
</template>

<script>
import PrivateAreaSelector from "@/components/private-area/private-area-selector"
import ProfileCustomerSidebar from "@/views/ProfileCustomer/ProfileCustomerSidebar"
import {mapState} from "vuex";

export default {
  name: "ProfileCustomerPage",

  components: {
    PrivateAreaSelector,
    ProfileCustomerSidebar
  },
  computed: {
    ...mapState({
      customerCode: (state) => state.customer.customerinfo.customerCode,
    }),
  }
}
</script>


<style lang="scss">

.custom-container-sticky-header {
  max-height: 500px;
  overflow: auto;

  .pc-sticky-heading {
    background-color: var(--gray);
    position: sticky!important;
    top: 0;

    z-index: 200;
  }
}

.custom-table-sticky-header {

  max-height: 500px;


  .table  {
    thead {
      th {
        position: sticky!important;
        top: 0px;

        z-index: 200;
      }
    }

    &.pc-filtered-table {
      thead {
        td {
          position: sticky!important;
          top: 0;
          padding: 0;
          border: none;

          background-color: var(--gray);

          z-index: 200;
        }

        th {
          //top: 100px;
        }
      }
    }
  }

} 
</style>

