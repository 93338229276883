import Validator from "../services/validation-service/validator-service";
export class Customer {
  //AnagraphicsInfo fileds
  customerCode;
  businessName;
  vatNumber;
  fiscalCode;
  sdiCode;
  pec;
  //CompanyPerspective fields
  marketSegmentation1;
  marketSegmentation2;
  marketSegmentation3;
  marketSegmentation4;
  marketCompetitor1;
  marketCompetitor2;
  totalConsumption;
  alceaPotential;
  isRetailerOfMetal;
  isRetailerOfWood;
  isRetailerOfConstruction;
  isRetailerOfBodywork;
  isRetailerOfOwnership;
  retailerShopWindows;
  retailerSurface;
  tntNextRequired;
  unconditionalDiscountPct;
  //Addresses fields
  addresses = [];
  /*
  legalAddress = new Address(true);
  businessAddress = new Address(false);
  shippingAddresses = sortShippingAddresses(
    new Array({
      ...new Address(false),
      ...{ code: String() },
    })
  );
   */
  contacts = [];
  billingEmails = [];
  transportDocumentEmails = [];
  msdsEmails = [];
  testSheetsEmails = [];
  //BankInformations fields
  bankName;
  iban;

  get legalAddress() {
    return getLegalAddress(this.addresses);
  }

  set legalAddress(address) {
    let indexAddressToReplace = this.addresses.findIndex(a => a.code === address.code);
    if(indexAddressToReplace > -1) {
      this.addresses[indexAddressToReplace] = address
    }
    else {
      this.addresses.push(address);
    }
  }

  get businessAddress() {
    return getBusinessAddress(this.addresses);
  }

  set businessAddress(address) {
    console.log('set businessAddress', address)
    if(address.code !== 'SL') {
      let indexAddressToReplace = this.addresses.findIndex(a => a.code === address.code);
      if (indexAddressToReplace > -1)
        this.addresses[indexAddressToReplace] = address
      else
        this.addresses.push(address);
    }
  }

  get shippingAddresses () {
    return sortShippingAddresses(this.addresses)
  }

  set shippingAddresses(shippingAddresses) {
    this.addresses = this.addresses.filter(a => ['SL', 'SA'].includes(a.code) && a.isShipping);
    if(shippingAddresses) {
      for (let address of shippingAddresses) {
        address.isShipping = true;
        if(!['SL', 'SA'].includes(address.code)) {
          let indexAddressToReplace = this.addresses.findIndex(a => a.code === address.code);
          if (indexAddressToReplace > -1)
            this.addresses[indexAddressToReplace] = address
          else
            this.addresses.push(address);
        }
      }
    }
  }

  set purchasingContact (purchasingContact) {
    let indexContactToReplace = this.contacts.findIndex(a => a.isPurchasing);

    if (indexContactToReplace > -1)
      this.contacts[indexContactToReplace] = purchasingContact
    else
      this.contacts.push(purchasingContact);
  }

  get purchasingContact () {
    console.log('get purchasingContact', this.contacts)
    let purchasingContact = this.contacts.find(a => a.isPurchasing);
    if(!purchasingContact) {
      purchasingContact = new Contact(false, true, true);
      purchasingContact.code = 'C001';
    }

    return purchasingContact;
  }
  set administrativeContact (administrativeContact) {
    let indexContactToReplace = this.contacts.findIndex(a => a.isAdministration);

    if (indexContactToReplace > -1)
      this.contacts[indexContactToReplace] = administrativeContact
    else
      this.contacts.push(administrativeContact);
  }

  get administrativeContact () {
    console.log('get administrativeContact', this.contacts)
    let administrativeContact = this.contacts.find(a => a.isAdministration);
    if(!administrativeContact) {
      administrativeContact = new Contact(true, false, true);
      administrativeContact.code = 'C002';
    }

    return administrativeContact;
  }

  set additionalContacts (additionalContacts) {
    this.contacts = this.contacts.filter(a => !a.isAdministration && !a.isPurchasing) || [];
    if(additionalContacts.length > 0)
      this.contacts.push(additionalContacts || []);
  }

  get additionalContacts () {
    let additionalContacts = this.contacts.filter(a => !a.isAdministration && !a.isPurchasing);
    console.log('get additionalContacts', additionalContacts)
    return additionalContacts;
  }


  constructor(emptyDraft, dataCustomer, agent) {
    if (emptyDraft) {
      this.businessName = "";
      this.vatNumber = "";
      this.fiscalCode = null;
      this.sdiCode = null;
      this.pec = null;
      this.addresses = [new Address(true, true)];
      this.contacts = [];
      this.billingEmails = [];
      this.transportDocumentEmails = [];
      this.msdsEmails = [];
      this.testSheetsEmails = [];
      this.bankName = "";
      this.iban = "";

      if (agent) {
        this.marketSegmentation1 = null;
        this.marketSegmentation2 = null;
        this.marketSegmentation3 = null;
        this.marketSegmentation4 = null;
        this.marketCompetitor1 = null;
        this.marketCompetitor2 = null;
        this.totalConsumption = 0;
        this.alceaPotential = 0;
        this.isRetailerOfMetal = false;
        this.isRetailerOfWood = false;
        this.isRetailerOfConstruction = false;
        this.isRetailerOfBodywork = false;
        this.isRetailerOfOwnership = false;
        this.retailerShopWindows = 0;
        this.retailerSurface = 0;
        this.tntNextRequired = false;
      }
    } else {
      if (dataCustomer) {

        this.businessName = dataCustomer.businessName;
        this.vatNumber = dataCustomer.vatNumber;
        this.fiscalCode = dataCustomer.fiscalCode;
        this.sdiCode = dataCustomer.sdiCode;
        this.pec = dataCustomer.pec;
        this.addresses = dataCustomer.addresses || [new Address(true, true)];
        this.contacts = sortContacts(dataCustomer.contacts);
        this.billingEmails = dataCustomer.billingEmails;
        this.transportDocumentEmails = dataCustomer.transportDocumentEmails;
        this.msdsEmails = dataCustomer.msdsEmails;
        this.testSheetsEmails = dataCustomer.testSheetsEmails;
        this.bankName = dataCustomer.bankName;
        this.iban = dataCustomer.iban;

        if (agent) {
          this.marketSegmentation1 = dataCustomer.marketSegmentation1;
          this.marketSegmentation2 = dataCustomer.marketSegmentation2;
          this.marketSegmentation3 = dataCustomer.marketSegmentation3;
          this.marketSegmentation4 = dataCustomer.marketSegmentation4;
          this.marketCompetitor1 = dataCustomer.marketCompetitor1;
          this.marketCompetitor2 = dataCustomer.marketCompetitor2;
          this.totalConsumption = dataCustomer.totalConsumption;
          this.alceaPotential = dataCustomer.alceaPotential;
          this.isRetailerOfMetal = dataCustomer.isRetailerOfMetal;
          this.isRetailerOfWood = dataCustomer.isRetailerOfWood;
          this.isRetailerOfConstruction = dataCustomer.isRetailerOfConstruction;
          this.isRetailerOfBodywork = dataCustomer.isRetailerOfBodywork;
          this.isRetailerOfOwnership = dataCustomer.isRetailerOfOwnership;
          this.retailerShopWindows = dataCustomer.retailerShopWindows;
          this.retailerSurface = dataCustomer.retailerSurface;
          this.tntNextRequired = dataCustomer.tntNextRequired;
        }
      }
    }
  }
}

export class Address {
  code;
  businessName;
  streetAddress1;
  streetAddress2;
  country;
  city;
  zipOrPostalCode;
  province;
  note;
  isDefault;
  isShipping;
  constructor(isLegal, emptyDraft, dataAddress, formToPostContact) {
    if (emptyDraft) {
      this.code = isLegal ? 'SL' : '';
      this.businessName= "";
      this.streetAddress1 = "";
      this.streetAddress2 = "";
      this.country = "";
      this.city = "";
      this.zipOrPostalCode = "";
      this.province = "";
      this.note = "";
      this.isDefault = false;
      this.isShipping = true;
    } else {
      if (dataAddress) {
        this.code = dataAddress.code;
        this.businessName = dataAddress.businessName;
        this.streetAddress1 = dataAddress.streetAddress1;
        this.streetAddress2 = dataAddress.streetAddress2;
        this.country = dataAddress.country;
        this.city = dataAddress.city;
        this.zipOrPostalCode = dataAddress.zipOrPostalCode;
        this.province = dataAddress.province;
        this.note = dataAddress.note;
        this.isDefault = dataAddress.isDefault
        this.isShipping = dataAddress.isShipping
      } else {
        if (formToPostContact) {
          this.code = Validator.valid(formToPostContact.code) ? formToPostContact.code : null;
          this.streetAddress1 = Validator.valid(
            formToPostContact.streetAddress1
          )
            ? formToPostContact.streetAddress1
            : null;
          this.streetAddress2 = Validator.valid(
            formToPostContact.streetAddress2
          )
            ? formToPostContact.streetAddress2
            : null;
          this.country = Validator.valid(formToPostContact.country)
            ? formToPostContact.country
            : null;
          this.city = Validator.valid(formToPostContact.city)
            ? formToPostContact.city
            : null;
          this.zipOrPostalCode = Validator.valid(
            formToPostContact.zipOrPostalCode
          )
            ? formToPostContact.zipOrPostalCode
            : null;
          this.notes = Validator.valid(formToPostContact.notes)
            ? formToPostContact.notes
            : null;
          this.businessName = Validator.valid(formToPostContact.businessName)
            ? formToPostContact.businessName
            : null;
          this.province = Validator.valid(formToPostContact.province)
            ? formToPostContact.province
            : null;
        }
      }
    }
  }
  equal(address) {
    return (
      address &&
      this.code === address.code &&
      //this.businessName == address.businessAddress &&
      this.streetAddress1 === address.streetAddress1 &&
      this.streetAddress2 === address.streetAddress2 &&
      this.country === address.country &&
      this.city === address.city &&
      this.zipOrPostalCode === address.zipOrPostalCode &&
      this.province === address.province &&
      this.note === address.note
    );
  }
}

export function getLegalAddress (arrayAddresses) {
  console.log('getLegalAddress', arrayAddresses)
  if(!arrayAddresses)
    return new Address(true, true, false);

  const address = arrayAddresses.find(a => "SL" === a.code);

  if(address)
    return new Address(true, false, false, address);

  return new Address(true, true);

}
export function getBusinessAddress (arrayAddresses) {
  // if(!arrayAddresses) {
  //   return getLegalAddress(arrayAddresses);
  // }

  const address = arrayAddresses.find(a => "SA" === a.code);

  if(address)
    return new Address(false, false, false, address);
  else
    return getLegalAddress(arrayAddresses);
}
export function sortShippingAddresses(arrayAddresses) {
  if(!arrayAddresses) {
    return [getLegalAddress(arrayAddresses)];
  }

  let maxIndex = arrayAddresses.reduce((max, ca) => {
    return ca.isShipping && ca.code && ca.code.substr(0,2) === 'SD' && !isNaN(parseInt(ca.code.slice(-3))) && parseInt(ca.code.slice(-3))  ?  parseInt(ca.code.slice(-3)) : max
  }, 0);

  let shippingAddresses = arrayAddresses.filter(a => a.isShipping).map((element, index) => {
    return new Address(false,false, false, Object.assign(element, {
      code: Validator.valid(element.code)
          ? element.code
          : "SD" + ("" + (++maxIndex)).padStart(3, "0")
    }));
  });
  return shippingAddresses.sort(function (a, b) {
    return a.code - b.code;
  });
}
export function sortContacts(arrayContacts) {
  let maxIndex = arrayContacts.reduce((max, ca) => {
    return ca.code && ca.code.substr(0,1) === 'C' && !isNaN(parseInt(ca.code.slice(-3))) && parseInt(ca.code.slice(-3)) > max ?  parseInt(ca.code.slice(-3)) : max
  }, 0);

  let contacts = arrayContacts.map((element, index) => {
    element.code = Validator.valid(element.code)
      ? element.code
        : "C" + ("" + (++maxIndex)).padStart(3, "0");
    return element;
  });
  return contacts.sort(function (a, b) {
    return a.code - b.code;
  }) || [];
}
export class Contact {
  code;
  isAdministration;
  isPurchasing;
  gender;
  firstName;
  lastName;
  role;
  department;
  email;
  phoneNumber;
  constructor(isAdministration, isPurchasing, emptyDraft, dataContact, userContact, formToPostContact) {
    if (emptyDraft) {
      this.isAdministration = isAdministration;
      this.isPurchasing = isPurchasing;
      this.code = "";
      this.gender = "";
      this.firstName = "";
      this.lastName = "";
      this.role = "";
      this.department = "";
      this.email = "";
      this.phoneNumber = "";
    } else {
      if (dataContact) {
        this.isAdministration = dataContact.isAdministration || isAdministration;
        this.isPurchasing =  dataContact.isPurchasing || isPurchasing;
        this.code = dataContact.code;
        this.gender = dataContact.gender;
        this.firstName = dataContact.firstName;
        this.lastName = dataContact.lastName;
        this.role = dataContact.role;
        this.department = dataContact.department;
        this.email = dataContact.email;
        this.phoneNumber = dataContact.phoneNumber;
      } else if (userContact) {
        this.isAdministration = isAdministration;
        this.isPurchasing =  isPurchasing;
        this.gender = userContact.gender;
        this.firstName = userContact.firstname;
        this.lastName = userContact.lastname;
        this.email = userContact.email;
        this.phoneNumber = userContact.telephone;
      } else if (formToPostContact) {
        this.isAdministration = formToPostContact.isAdministration;
        this.isPurchasing = formToPostContact.isPurchasing;
        this.code = formToPostContact.code;
        this.gender = Validator.valid(formToPostContact.gender)
          ? formToPostContact.gender
          : null;
        this.firstName = Validator.valid(formToPostContact.gender)
          ? formToPostContact.firstName
          : null;
        this.lastName = Validator.valid(formToPostContact.gender)
          ? formToPostContact.lastName
          : null;
        this.role = Validator.valid(formToPostContact.gender)
          ? formToPostContact.role
          : null;
        this.department = Validator.valid(formToPostContact.department)
            ? formToPostContact.department
            : null;
        this.email = Validator.valid(formToPostContact.gender)
          ? formToPostContact.email
          : null;
        this.phoneNumber = Validator.valid(formToPostContact.gender)
          ? formToPostContact.phoneNumber
          : null;
      }
    }
  }
}
