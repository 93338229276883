export const formCustomer = {
  vatNumber: "Partita IVA",
  fiscalCode: "Codice Fiscale",
  noPrefix: "nessun prefisso",
  sdiCode: "Codice SDI",
  pec: "PEC",
  streetAddress1: "Indirizzo Riga 1",
  streetAddress2: "Indirizzo Riga 2",
  city: "Città",
  billing: "Fattura",
  transportDocument: "DDT",
  msds: "MSDS",
  testSheets: "Collaudo",
  businessAddress: "Sede amministrativa",
  businessAddressSameAslegalAddress:
    "La sede amministrativa coincide con la sede legale?",
  legalAddress: "Sede legale",
  shippingAddressesSameAslegalAddress:
    "La sede di spedizione coincide con la sede legale?",
  shippingAddresses: "Sede di spedizione",
  addAddress: "Aggiungi indirizzo",
  purchasingContact: "Contatto Acquisti",
  administrativeContact: "Contatto Amministrazione",
  documentsToRecive: "Documenti da inviare a questo contatto",
  documentsRecipient: "Destinatari documenti",
  additionalContact: "Contatto Aggiuntivo",
  additionalContacts: "Contatti Aggiuntivi",
  billingRecipient: "Destinatario fattura",
  transportDocumentRecipient: "Destinatario documenti di trasporto",
  msdsRecipient: "Destinatario schede di sicurezza",
  testSheetsRecipient: "Destinatario schede di collaudo",
  bankName: "Banca",
  iban: "IBAN",
  customerCode: "Codice Cliente",
  customerCodeShort: "Cliente",
  customerCodeText: "Se disponi del codice cliente (nel nuovo formato) compila il campo per richiedere d'essere associato direttamente",
  marketSegmentation: "Segmentazione",
  marketSegmentationPlaceholder:'Tipologia di appartenenza',
  resellingType: "Tipologia",
  marketCompetitor :'Concorrente',
  marketCompetitorPlaceholder:'Azienda concorrente',
  totalConsumption:'Consumo totale ',
  totalConsumptionPlaceholder: 'Consumo totale in €',
  alceaPotential:'Potenziale Alcea',
  alceaPotentialPlaceholder:'Potenziale in € per Alcea',
  tntNextRequired:'Necessita TNT NEXT'
};
