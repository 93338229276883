<template>
  <section>
    <!-- private area selector -->
    <private-area-selector></private-area-selector>


    <div class="pc-container bb">
      <!-- search component -->
      <div class="item-2 p-3">
        <h3 class="text-uppercase pb-3" >{{ $t("titles.selectClient") }}</h3>

        <b-form class="bck">
          <b-input-group>
            <b-input id="search"
                     name="search"
                     type="search"
                     class="bck"
                     style="border: none; border-radius: 0; box-shadow: none;"
                     :placeholder="$t('actions.search')"
                     v-model="searchValue">
            </b-input>

            <b-button @click="search"
                      type="submit"
                      variant="outline-secondary"
                      style="border: none; border-radius: 0; box-shadow: none;">
              <b-icon-search aria-hidden="true"></b-icon-search>
            </b-button>
          </b-input-group>
        </b-form>
      </div>


      <!-- customers -->
      <div class="item">
        <!-- no results -->
        <div class="justify-content-center" v-if="customers.length === 0">
          <h6 class="col danger text-center">{{ $t("labels.NoCustomers") }}</h6>
        </div>

        <b-col>
          <b-row>
            <div class="col-md-6 pa-card adw-pointer"
                 v-for="customer in customers"
                 :key="customer.customerCode"
                 @click="toCustomer(customer.customerCode)">
              <h3 class="font-weight-bold mb-2">{{ customer.businessName }}</h3>
              <h2>{{ $t("formCustomer.customerCode") }}: {{ customer.customerCode }}</h2>
            </div>
          </b-row>
        </b-col>
      </div>
    </div>


    <div class="pc-container">
      <!-- requests title -->
      <div class="item-2 bck">
        <h3 class="text-uppercase m-3">{{ $t("titles.newRequest") }}</h3>

        <!-- add new request -->
        <div class="text-center">
          <span id="new-request-btn">
            <b-button class="mb-3 mx-5"
                      variant="outline-primary"
                      @click="$router.push('/customer-request')">
              <b-icon aria-hidden="true" icon="plus"></b-icon>
              {{ $t("actions.newRequest") }}
            </b-button>
          </span>

          <p class="mx-4">{{ $t("requestStatus.agentRequestCustomerText") }}</p>
        </div>
      </div>


      <!-- requests -->
      <div class="item bck">
        <!-- request -->
        <b-col>
          <b-row>
            <div class="col-md-6 pa-card"
                 v-for="request in requests"
                 :key="request.vatNumber">
              <b-row>
                <b-col>
                  <h3 class="font-weight-bold mb-2">{{ request.businessName }}</h3>
                  <h2>{{ $t("formCustomer.vatNumber") }}: {{ request.vatNumber }}</h2>
                </b-col>

                <b-col cols="3" class="text-md-right">
                  <b-icon font-scale="0.8" aria-hidden="true" icon="circle-fill" :style="statusClass(request.status)"></b-icon>
                  {{ $t("requestStatus." + request.status) }}
                </b-col>
              </b-row>

              <b-row>
                <b-col class="text-md-right">
                  <b-button variant="link" style="color: red;" v-if="request.status === 'DRAFT'" @click="deleteDraft(request.id)">
                    {{ $t("actions.delete") }}
                  </b-button>

                  <b-button variant="link" v-if="request.status === 'DRAFT'" @click="$router.push('/customer-request?id=' + request.id)">
                    {{ $t("actions.continue") }}
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </b-row>
        </b-col>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import PrivateAreaSelector from "@/components/private-area/private-area-selector";
import ProfileAgentListCustomerRequest from "@/components/profile-agent-listcustomerrequest";

export default {
  name: "AgentProfile",

  components: {
    PrivateAreaSelector,
    ProfileAgentListCustomerRequest
  },

  data() {
    return {
      n_row: 30,
      page_index: 0,
      customers: [],
      requests: [],
      searchValue: "",
    };
  },
  computed: {
    ...mapState({
      userRole: (state) => state.auth.user.role,
      requestStatus: (state) => state.request.request.status,
      request: (state) => state.request.request.info,
    }),
  },

  methods: {
    search(search) {
      search.preventDefault();
      if (this.searchValue !== '') {
        this.$store
            .dispatch('searchCustomerAgent', this.searchValue)
            .then((c) => {
              this.customers = c.content;
            })
            .catch((e) => {
              this.customers = [];
            });
      } else {
        this.loadAgentData();
      }
    },

    toCustomer(code) {
      if (!this.$store.getters.getAgentCustomersLoaded) {
        const myCustomerDispatch = this.userRole === "COMMERCIAL" ? "commercialMyCustomers" : "agentMyCustomers";
        this.$store.dispatch(myCustomerDispatch, { page: 0, row: 100 });
      }
      const getCustomerDispatch = this.userRole === "COMMERCIAL" ? "customerCommercial" : "customerAgent";
      this.$store
          .dispatch(getCustomerDispatch, code)
          .then((u) => {
            console.log("customer impersonation done", code);
            this.$router.push("/profile-customer");
          })
          .catch((e) => console.error(e));
    },

    statusClass(requestStatus) {
      if (requestStatus === "NONE") {
        return { color: "white" };
      }
      if (requestStatus === "DRAFT") {
        return { color: "grey" };
      }
      if (requestStatus === "PENDING") {
        return { color: "yellow" };
      }
      if (requestStatus === "ACCEPTED") {
        return { color: "green" };
      }
      if (requestStatus === "REFUSED") {
        return { color: "red" };
      }
    },

    loadAgentData() {
      this.$store
          .dispatch("agentMyself", { page: this.page_index, row: this.n_row })
          .then((c) => {
            this.customers = c.customers;
            this.requests = c.requests;
          })
          .catch((e) => {
            this.customers = [];
            this.requests = [];
          });
    },

    deleteDraft(id) {
      const deleteRequestDispatch = this.userRole === "COMMERCIAL" ? "commercialDeleteRequest" : "agentDeleteRequest";
      
      this.$store
          .dispatch(deleteRequestDispatch, id)
          .then((c) => {
            this.loadAgentData();
          })
          .catch((e) => {
            console.log(e);
          });
    },
  },

  mounted() {
    this.loadAgentData();
  },
};
</script>
