import CustomerService from "../services/api-services/customer-service";
import AgentService from "../services/api-services/agent-service";
import CommercialService from "../services/api-services/commercial-service";

// const userType=localStorage.getItem('userType');
const initialState = {
  status: { loggedInAsCustomer: false },
  customerinfo: null,
  customerEditable: false,
  customerVersion: 0,
};

export const customer = {
  state: initialState,
  actions: {
    async checkCustomerEdit({ commit, dispatch, getters }, code) {
      return CustomerService.checkCustomerEdit(
        code ? code : getters.getCustomerCode
      ).then(
        (response) => {
          console.log(response);
          let ok = response.status == 404;
          commit("customerEditable", ok);
          return Promise.resolve(response);
        },
        (error) => {
          let ok = error.status == 404;
          if (ok) {
            commit("customerEditable", ok);
            return Promise.resolve(error);
          } else {
            commit("customerLoadOut");
            return Promise.reject(error);
          }
        }
      );
    },
    customerMyself({ commit, dispatch }) {
      return CustomerService.getMyselfUserCustomer().then(
        (user) => {
          console.log(user.data);
          dispatch("checkCustomerEdit", user.data.customerCode);
          commit("customerLoadIn", user.data);
          return Promise.resolve(user.data);
        },
        (error) => {
          commit("customerLoadOut");
          return Promise.reject(error);
        }
      );
    },
    async customerAgent({ commit, dispatch, getters }, code) {
      await AgentService.getSingleCustomerProfile(code).then(
        (user) => {
          dispatch("checkCustomerEditAgent", user.data.customerCode);
          commit("customerLoadIn", user.data);
          dispatch("getProductsCart").catch((error) => Promise.reject(error));
          return Promise.resolve(user.data);
        },
        (error) => {
          commit("customerLoadOut");
          return Promise.reject(error);
        }
      );
    },
    async customerCommercial({ commit, dispatch, getters }, code) {
      await CommercialService.getSingleCustomerProfile(code).then(
        (user) => {
          dispatch("checkCustomerEditCommercial", user.data.customerCode);
          commit("customerLoadIn", user.data);
          dispatch("getProductsCart").catch((error) => Promise.reject(error));
          return Promise.resolve(user.data);
        },
        (error) => {
          commit("customerLoadOut");
          return Promise.reject(error);
        }
      );
    },
    postEditRequest({ commit, dispatch, getters }, form) {
      form = { ...form, ...{ customerVersion: getters.getCustomerVersion } };
      return CustomerService.postEditRequest(form).then(
        (reponse) => {
          console.log(reponse.data);
          dispatch("checkCustomerEdit", reponse.data.customerCode);

          return Promise.resolve(reponse.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    postInvite({ commit }, formRequest) {
      return CustomerService.postInvite(formRequest).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    deleteInvitation({ commit }, invitee) {
      return CustomerService.deleteInvite(invitee).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },

    logoutCustomer({ commit }) {
      commit("customerLoadOut");
    },
  },
  mutations: {
    customerLoadIn(state, user) {
      state.status.loggedInAsCustomer = true;
      state.customerinfo = user;
      state.customerVersion = user.version;
    },
    customerLoadOut(state) {
      state.status.loggedInAsCustomer = false;
      state.customerinfo = {};
      state.customerEditable = false;
      state.customerVersion = 0;
    },
    customerEditable(state, update) {
      state.customerEditable = update;
    },

    updateFailure() {},
  },
  getters: {
    getCustomerInfo: (state) => state.customerinfo,
    getCustomerCode: (state) => state.customerinfo.customerCode,
    getLoggedInAsCustomer: (state) => state.status.loggedInAsCustomer,
    getEditable: (state) => state.customerEditable,
    getCustomerVersion: (state) => state.customerVersion,
  },
};
