export const sds = {
    form: {
        site: "PRODUCTION SITE",
        allSites: "All sites",
        serial: "SERIES",
        color: "COLOR",
        product: "PRODUCT",
        customerCode: "CUSTOMER",
        year: "YEAR",
        batch: "BATCH",
        orderCode: "ORDER CODE",
        language: "LANGUAGE",
        selectLanguage: "Select a language",
    },
    results: {
        yearMonth: 'Year-Month',
        batch: 'Batch',
        product: 'Product',
        recipe: 'Recipe',
        versionOrBarra: 'Version / Barra',
    },
    disclaimer: {
        batch: "Iniziare a compilare il campo di seguito con il numero del lotto desiderato (sia formato Glink che formato SAGE X3) per vedere i risultati della ricerca, selezionare il lotto per visualizzare le SDS per le lingue disponibili.",
        customer: "I risultati di ricerca fanno sempre riferimento ad un lotto di produzione legato ad un cliente, i lotti legati ad un cliente sono solo nel formato nuovo di SAGE X3 (dopo il 14/10/2024)."
    }
};
