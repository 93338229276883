<template>
  <div class="bx">
      <h3 class="private-area-selected text-center pb-2 mb-0 border-bottom text-uppercase" >
        {{ $t("titles.becomeCustomer") }}
      </h3>
      
    <section class="px-5 py-3" v-if="feedbackStatus != 'Success'">

      <steps-bar
          class="my-4"
          :curretStep="currentStep"
          :steps="steps"
          @setStep="setStep"
          :completed="completed"
          @stepValidation="validateCurretStep">
      </steps-bar>

      <div class="row justify-content-center">
        <div class="col-md-12">
          <feedback-alert
              v-if="feedbackStatus"
              :status="feedbackStatus"
              :message="feedbackMessage"
              @clearStatus="clearStatus"
              @back="exit('/profile-user')"
          ></feedback-alert>
        </div>
      </div>

      <form-client-anagraphics
          v-show="currentStep.name == 'Anagraphics' || currentStep.name == 'AnagraphicsIT'"
          :ref="'Anagraphics'"
          :form="form"
          :user="user"
          :currentStep="currentStep"
          :invalidErrors="validation.invalid"
          :edit="false"
          @fieldUpdated="validateCurretStep">
      </form-client-anagraphics>

      <form-client-company-type
          v-show="currentStep.name == 'CompanyPerspective'"
          :ref="'CompanyPerspective'"
          :formRq.sync="form"
          :currentStep="currentStep"
          :invalidErrors="validation"
          :user="user"
          :isMounted="isMounted"
          @fieldUpdated="validateCurretStep">
      </form-client-company-type>

      <form-client-addresses
          v-if="requestIsLoaded"
          v-show="currentStep.name == 'Addresses'"
          :ref="'Addresses'"
          :user="user"
          :businessName="form.businessName"
          :shippingAddresses.sync="form.shippingAddresses"
          :legalAddress.sync="form.legalAddress"
          :businessAddress.sync="form.businessAddress"
          :currentStep="currentStep"
          :invalidErrors="validation"
          @fieldUpdated="validateCurretStep">
      </form-client-addresses>

      <form-client-contacts
          v-if="requestIsLoaded && !isUserAgentOrCommercial"
          v-show="currentStep.name == 'Contacts'"
          :ref="'Contacts'"
          :form.sync="form"
          :currentStep="currentStep"
          :invalidErrors="validation"
          :user="user"
          :isMounted="isMounted"
          @fieldUpdated="validateCurretStep">
      </form-client-contacts>

      <form-client-contacts-agent
          v-if="requestIsLoaded && isUserAgentOrCommercial"
          v-show="currentStep.name == 'ContactsAgent'"
          :ref="'ContactsAgent'"
          :form.sync="form"
          :currentStep="currentStep"
          :invalidErrors="validation"
          :user="user"
          :isMounted="isMounted"
          @fieldUpdated="validateCurretStep">
      </form-client-contacts-agent>

      <form-client-bank-information
          v-show="currentStep.name == 'BankInformation'"
          :ref="'BankInformation'"
          :form="form"
          :currentStep="currentStep"
          :invalidErrors="validation.invalid"
          @fieldUpdated="validateCurretStep">
      </form-client-bank-information>

      <customer-recap
          v-show="currentStep.name === 'ConfirmRequest'"
          :ref="'ConfirmRequest'"
          :form="form"
          :currentStep="currentStep"
          :invalidErrors="validation">
      </customer-recap>

      <div class="row justify-content-end">
        <adw-button class="w-50"
              v-if="currentStep.name !== lastForm"
              @button-click="draft(null, currentStep.name === lastForm)">
            <h3>{{ $t("actions.next") }}</h3>
        </adw-button>

        <adw-button :class="'w-50 ' + (isLoading ? 'disabled' : '')"
              v-if="currentStep.name === lastForm"
              @button-click="complete">
            <h3>{{ $t("actions.confirm") }}</h3>
        </adw-button>
      </div>

      <div class="row justify-content-end">
        <adw-button class="w-50 black"
              v-if="currentStep.name !== lastForm"
              @button-click="isUserAgentOrCommercial?draft('/profile-agent'):draft('/profile-user')">
            <h3>{{ $t("actions.saveDraft") }}</h3>
        </adw-button>

      </div>

      <b-modal
          centered
          id="customerRequestExistingVat"
          ref="customerRequestExistingVat"
          :hide-footer="isUserAgentOrCommercial"
          :title="$t('alert.customerRequestExistingVatTitle')"
          @ok="$router.push('/profile-user?openLinkCustomerModal=true')"
          :ok-title="$t('actions.goToClientAssignement')"
          :cancel-title="$t('actions.back')"
          cancel-variant="link">
        <p v-if="$store.getters.getUserRole === 'USER'" class="my-4">
          {{ $t("alert.customerRequestExistingVat") }}
        </p>
        <p v-if="isUserAgentOrCommercial" class="my-4">
          {{ $t("alert.agentCustomerRequestExistingVat") }}
        </p>
      </b-modal>

    </section>
    <section>

      <div class="row justify-content-center">
        <div class="col-md-12">
          <feedback-alert
              v-if="feedbackStatus == 'Success'"
              :status="feedbackStatus"
              :message="feedbackMessage"
              @back="isUserAgentOrCommercial?exit('/profile-agent'):exit('/profile-user')">
          </feedback-alert>
        </div>
      </div>
    </section>

  </div>
</template>
<script>
import StepsBar from "@/components/global-components/steps-bar.vue";
import FormClientAnagraphics from "@/components/form-client-anagraphics.vue";
import FormClientAddresses from "@/components/form-client-addresses.vue";
import FormClientContacts from "@/components/form-client-contacts.vue";
import FormClientBankInformation from "@/components/form-client-bankInformation.vue";
import FormClientCompanyType from "@/components/form-client-companyType.vue";
import FormClientContactsAgent from "@/components/form-client-contacts-agent.vue";
import CustomerRecap from "@/components/confirm-request/customer-recap.vue";
import AdwButton from "@/components/global-components/adw-button";


import AgentService from "@/services/api-services/agent-service";
import CommercialService from "@/services/api-services/commercial-service";

import {Customer, sortShippingAddresses, } from "@/models/customer";

import { mapState } from "vuex";
import Validator from "@/services/validation-service/validator-service";
import ErrorManager from "@/services/errors-service/errors-manager";
import FeedbackAlert from "@/components/feedback-alert.vue";

export default {
  components: {
    FormClientAnagraphics,
    FormClientCompanyType,
    FormClientAddresses,
    FormClientContacts,
    FormClientContactsAgent,
    FormClientBankInformation,
    StepsBar,
    FeedbackAlert,
    CustomerRecap,
    AdwButton,
  },
  name: "CustomerRegistration",
  data() {
    return {
      isMounted: false,
      step: 0,
      baseStep: {
        name: "",
        position: 0,
        fields: [],
        form: {},
      },
      //TODO computed if user agent is a different sequence ["AnagarphicInfo", "CompanyPerspective", "Addresses", "Contacts", "BankInformation"]
      //steps: ["Anagraphics",  "Addresses","Contacts",  "BankInformation", "ConfirmRequest",],
      completed: ["Anagraphics"],
      validation: {
        invalid: [],
      },
      lastForm: "ConfirmRequest",
      feedbackStatus: null,
      feedbackMessage: "",
      request: null,
      requestIsLoaded: false,
      isLoading: false,
    };
  },

  async created() {
    const id = this.$route?.query?.id;

    if (id) {
      this.requestId = id;

      if(this.$store.getters.getUserRole === "AGENT") {
        AgentService.getCustomerRequestDraft(id).then(
          (response) => {
            this.request = response.data;
            this.requestIsLoaded = true;
          });
      } else if(this.$store.getters.getUserRole === "COMMERCIAL"){
        CommercialService.getCustomerRequestDraft(id).then(
          (response) => {
            this.request = response.data;
            this.requestIsLoaded = true;
          });
      }
    } else {
      this.requestIsLoaded = true;
    }
  },

  computed: {
    ...mapState({
      requestStatus: (state) => state.request.request.status,
      loggedInPrivateArea: (state) => state.user.status.loggedInPrivateArea,
      loggedIn: (state) => state.auth.status.loggedIn,
      user: (state) => state.user.userinfo,
    }),

    isUserAgentOrCommercial() {
      return this.$store.getters.getUserRole === "AGENT" || this.$store.getters.getUserRole === "COMMERCIAL"
    },

    steps() {
      if (this.isUserAgentOrCommercial) {
        return [
          "Anagraphics",
          "CompanyPerspective",
          "Addresses",
          "ContactsAgent",
          "ConfirmRequest",
        ];
      } else {
        return [
          "Anagraphics",
          "Addresses",
          "Contacts",
          "BankInformation",
          "ConfirmRequest",
        ];
      }
    },
    form: {
      get() {
        let agent = this.isUserAgentOrCommercial;

        if (this.request) {
          let empty = false;
          let formC = new Customer(empty, this.request, agent);
          if (!agent) {
            formC.businessName = formC.businessName
                ? formC.businessName
                : this.user.businessName;
          }
          return formC;
        } else {
          let empty = true;
          let formC = new Customer(empty, null, agent);
          if (!agent) {
            formC.businessName = formC.businessName
                ? formC.businessName
                : this.user.businessName;
          }
          return formC;
        }
      },
      set(form) {
        let agent = this.isUserAgentOrCommercial;
        let empty = false;
        let formC = new Customer(empty, form, agent);

        return formC;
      },
    },
    currentStep() {
      let step = this.baseStep;
      step.name = this.steps[this.step]
      if (this.isMounted) {
        let Refs = this.$refs[this.steps[this.step]];
        step = {
          name: Refs.self,
          position: this.step,
          fields: Refs.fields,
        };
      }
      step.position = this.step;
      return step;
    },
  },
  methods: {
    showModal() {
      this.$refs.customerRequestExistingVat.show();
    },
    currentForm() {
      let form = {};
      let Refs = this.$refs[this.steps[this.step]];
      Refs.fields.forEach((key) => {
        form[key] = this.form[key];
      });
      return form;
    },
    validationState(contextRef) {
      return this.invalidErrors.find(
          (invEl) => invEl.name.toLowerCase() == contextRef.toLowerCase()
      )
          ? false
          : null;
    },

    validationError(contextRef) {
      if (this.invalidErrors.length > 0) {
        let find =
            this.invalidErrors.find(
                (invEl) => invEl.name.toLowerCase() == contextRef.toLowerCase()
            ) || null;
        return find ? this.$i18n.t(find.error) : null;
      }
    },
    setStep(step) {
      if (this.currentStep.position < step) {
        this.validateCurretStep() ? (this.step = step) : null;
      } else {
        this.step = step;
      }
    },
    clearStatus() {
      this.feedbackStatus = null;
      this.feedbackMessage = "";
    },
    getExtravalues() {
      let contactsRefName =
          this.isUserAgentOrCommercial
              ? "ContactsAgent"
              : "Contacts";
      let contactsRef = this.$refs[contactsRefName];
      let extravalues = {
        businessAddress: {
          zipOrPostalCode: {
            name: "country",
            value: this.form.businessAddress.country,
          },
        },
        legalAddress: {
          zipOrPostalCode: {
            name: "country",
            value: this.form.legalAddress.country,
          },
        },
        shippingAddresses: {},
        purchasingContact: {
          phoneNumber: {
            name: "telephonePrefix",
            value: contactsRef.purchasingContactTelephone,
          },
        },
        administrativeContact: {
          phoneNumber: {
            name: "telephonePrefix",
            value: contactsRef.administrativeContactTelephone,
          },
        },
      };
      this.form.shippingAddresses.forEach((ad, index) => {
        extravalues.shippingAddresses[index] = {
          zipOrPostalCode: {
            name: "country",
            value: this.form.shippingAddresses[index].country,
          },
        };
      });
      if (this.isUserAgentOrCommercial) {
        extravalues = { ...extravalues, ...{ additionalContacts: {} } };
        if(this.form.additionalContacts) {
          this.form.additionalContacts.forEach((ad, index) => {
            extravalues.additionalContacts[index] = {
              phoneNumber: {
                name: "telephonePrefix",
                value:
                    this.$refs.ContactsAgent.additionalContactsTelephones[index],
              },
            };
          });
        }
      }
      let returnvalue = {};
      let Refs = this.$refs[this.steps[this.step]];
      Refs.fields.forEach((key) => {
        extravalues[key] ? (returnvalue[key] = extravalues[key]) : null;
      });
      return returnvalue;
    },
    //emitted if currentStep.name== name
    draft(exit, end) {
      // this.form.shippingAddresses = sortShippingAddresses(
      //     this.form.shippingAddresses
      // );
      if (this.validateCurretStep()) {
        let dispatch = "updateCustomerRequest";
        if (this.$store.getters.getUserRole === "COMMERCIAL") {
          dispatch = "commercialUpdateCustomerRequest";
        } else if (this.$store.getters.getUserRole === "AGENT") {
          dispatch = "agentUpdateCustomerRequest";
        }
        this.post(dispatch, exit, end);
      } else {
        console.error("Invalid Form");
      }
    },
    validateCurretStep() {
      this.validation = Validator.validateForm(
          this.currentStep.name,
          this.currentForm(),
          this.getExtravalues()
      );
      if (
          this.validation.invalid.length > 0 ||
          this.currentStep.fields.find((f) => {
            return this.validation[f] ? this.checkValidation(f) : false;
          })
      ) {
        this.completed.filter((x) => {
          return;
        });
        return false;
      } else {
        return true;
      }
    },
    checkValidation(invalidEl) {
      if (Array.isArray(this.validation[invalidEl])) {
        return this.validation[invalidEl].find((el) => {
          return el.invalid.length > 0;
        });
      } else {
        return this.validation[invalidEl].invalid
            ? this.validation[invalidEl].invalid.length > 0
            : null;
      }
    },
    next(end) {
      this.feedbackStatus = null;
      this.feedbackMessage = "";
      if (end) {
        this.complete();
      } else {
        this.step = this.step + 1;
        this.completed.push(this.steps[this.step]);
      }
    },
    exit(exit) {
      this.$router.push(exit);
    },

    complete() {
      if(this.isLoading) {
        return;
      }

      let dispatch = "postCustomerRequest";
      if (this.$store.getters.getUserRole == "COMMERCIAL") {
        dispatch = "commercialPostCustomerRequest";
      } else if (this.$store.getters.getUserRole == "AGENT") {
        dispatch = "agentPostCustomerRequest";
      }
      this.post(dispatch);
    },
    async post(dispatch, exit, end) {
      this.isLoading = true;
      if (this.requestId) {
        this.form.id = this.requestId;
      }

      await this.$store
          .dispatch(dispatch, this.form)
          .then((res) => {
            if (dispatch === "postCustomerRequest" || dispatch === "agentPostCustomerRequest" || dispatch === "commercialPostCustomerRequest") {
              this.feedbackStatus = "Success";
              this.feedbackMessage = "sendRequestSuccess";
            } else {
              exit ? this.exit(exit) : this.next(end);
            }
            this.requestId = res.data.id;
          })
          .catch((error) => {
            console.error(error);
            if (error.status === 409) {
              this.showModal();
            } else {
              this.feedbackStatus = "FailDismissable";
              this.feedbackMessage = ErrorManager.feedback(error);
            }
          }).finally(() => {
            this.isLoading = false;
          });
    },
  },

  mounted() {
    this.isMounted = true;
  }
}
</script>
