export const titles = {
  welcome: "Welcome to ADW!",
  login: "Login",
  faq: "Video Tutorial",
  profile: "Profil",
  profileCustomer: "Customer profile",
  profileUser: "User profile",
  register: "Rejestracja nowego użytkownika",
  forgotPassword: "Password recovery",
  newPassword: "New Password",
  confirmRegistration: "Confirm registration",
  privateArea: "Private area",
  personalInformation: "Personal informations",
  companyInformation: "Company Information",
  notificationEmail: "Login notifications",
  anagraphicInfo: "Personal information",
  addresses: "Addresses",
  contacts: "Contacts",
  bankInformation: "Bank information",
  confirmRequest: "Confirmation required",
  becomeCustomerInvitation: "Do you want to become an Alcea customer?",
  becomeCustomer: "Become a customer",
  becomeCustomerText: "Fill out the request to become our customer, you will have access to all the products in our catalogs and you can request orders and offers directly from your private area.",
  confirmNewRequest: "Create a new request?",
  invitationsUser: "Invitations received to become a customer",
  invitationsCustomer: "Invitations sent",
  newInvite: "New Invitation",
  invitations: "Invitations",
  selectClient: "Select a customer to manage orders and offers",
  newRequest: "Requests for new customers",
  businessTypology: "Company typology",
  resalesInfo: "Resale information",
  outlook: "Outlooks",
  areaAgent: "My Customers",
  editCustomer: "Request Edit Profile",
  orderedProducts: "Ordered products",
  customerRequests: "Requests for orders / offers",
  backToAgent: "Change customer",
  orders: "Orders",
  cart: "Cart",
  notAssigned: "Price not assigned",
  checkoutOrder: "Order request checkout",
  checkoutOffer: "Offer request checkout",
  productSearch: "Product search",
  contactUs: "Contacts",
  loginTDSText: "Log in to download TDS",
  upload: "ATTENZIONE: Caricando un nuovo file verrà sovrascritta l'alberatura della ricerca prodotti B2C, accertarsi che il proprio responsabile abbia approvato il caricamento.",
  RegistrationWithoutWS: "Register without a website",
  startProductSearch: "Do you want to discover our products?",
  contactArea: "Customer Service",
  sdsSearch: "SDS Search",
  sdsSearchWarehouse: "Search by Site",
  sdsSearchBatch: "Search by Batch",
  sdsSearchCustomer: "Search by Customer",
  sdsSearchDocument: "Search by Document",
  sdsSearchProduct: "Search by product",
};
