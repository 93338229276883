<template>
  <div class="row">
    <h2>{{ $t("titles.addresses") }}</h2>
    <div class="col-md-12">
      <h3>{{ $t("formCustomer.legalAddress") }}</h3>
      <card-address
        :isShippingAddress="false"
        :address.sync="localLegalAddress"
        :invalidErrors="
          invalidErrors.legalAddress ? invalidErrors.legalAddress.invalid : []
        "
      ></card-address>
    </div>
    <div class="col-md-12">
      <span class=""
      ><b-checkbox v-model="businessAddressSameAslegalAddress">
          {{ $t("formCustomer.businessAddressSameAslegalAddress") }}</b-checkbox
      ></span
      >
      <h3>{{ $t("formCustomer.businessAddress") }}</h3>
      <card-address
          :isShippingAddress="false"
          :disabled="businessAddressSameAslegalAddress"
          :invalidErrors="
          invalidErrors.businessAddress
            ? invalidErrors.businessAddress.invalid
            : []
        "
          :address.sync="localBusinessAddress"
      ></card-address>
    </div>
    <div class="col-md-12">
      <!--  max 500 -->
      <span class="">
        <b-checkbox v-model="shippingAddressesSameAslegalAddress">
          {{
            $t("formCustomer.shippingAddressesSameAslegalAddress")
          }}</b-checkbox
        >
      </span>
      <h3>{{ $t("formCustomer.shippingAddresses") }}</h3>
      <card-address
        v-for="(ad, index) in localShippingAddresses"
        :isShippingAddress="true"
        :key="index"
        :disabled="index == 0 && shippingAddressesSameAslegalAddress"
        v-bind:address.sync="localShippingAddresses[index]"
        :dismissable="index > 0"
        :invalidErrors="
          invalidErrors.shippingAddresses &&
          invalidErrors.shippingAddresses[index]
            ? invalidErrors.shippingAddresses[index].invalid
            : []
        "
        @removeAddress="removeAddress(index)"
      >
      </card-address>
      <b-button @click="addAddress">{{
        $t("formCustomer.addAddress")
      }}</b-button>
    </div>
  </div>
</template>
<script>
import cardAddress from "./card-address.vue";
import { Address } from "../models/customer";
export default {
  components: { cardAddress },
  name: "Addresses",
  props: {
    businessName: String,
    shippingAddresses: Array(),
    legalAddress: Object(),
    businessAddress: Object(),
    user: Object(),
    curretStep: Object(),
    completed: Boolean(),
    invalidErrors: Array(),
  },
  data() {
    return {
      self: "Addresses",
      fields: ["businessAddress", "legalAddress", "shippingAddresses"],
      businessAddressSameAslegalAddress: false,
      shippingAddressesSameAslegalAddress: false,
      localBusinessAddress: this.businessAddress,
      localLegalAddress: this.legalAddress,
      localShippingAddresses: this.shippingAddresses,
    };
  },
  computed: {
    legalAddressWatcher() {
      return this.localLegalAddress;
    },
  },
  methods: {
    addAddress() {
      if (this.localShippingAddresses.length < 500) {
        let empty = true;
        const maxIndex = this.localShippingAddresses.reduce(
            (mi, element) => element.code && element.code.substr(0,2) === 'SD' && !isNaN(parseInt(element.code)) ?  parseInt(element.code) : mi,
            0,
        );
        let address = {
          ...new Address(),
          ...{
            code: "SD" + ("" + (maxIndex + 1)).padStart(3, "0"),
          },
        };
        this.localShippingAddresses.push(address);
        this.$emit("update:shippingAddresses", this.localShippingAddresses);
      }
    },
    removeAddress(address) {
      this.localShippingAddresses.splice(address, 1);
      this.$emit("update:shippingAddresses", this.localShippingAddresses);
    },
  },
  created() {
    this.localLegalAddress = this.legalAddress;
    this.localLegalAddress.businessName = this.businessName;
    this.localBusinessAddress = this.businessAddress;
    this.localShippingAddresses = this.shippingAddresses;

    this.businessAddressSameAslegalAddress = !this.businessAddress;
    this.shippingAddressesSameAslegalAddress = !this.shippingAddresses[0];

    /*
    if(this.shippingAddressesSameAslegalAddress) {
      this.localShippingAddresses[0].businessName = this.businessName;
    }
     */

    this.$emit("update:shippingAddresses",this.localShippingAddresses);
  },
  watch: {
    businessAddressSameAslegalAddress: function (newVal, oldVal) {
      this.localBusinessAddress = newVal
        ? this.legalAddressWatcher
        : {...new Address(),...{
              businessName: this.businessName,
              code: 'SA'
            }};
      this.$emit("update:businessAddress",this.localBusinessAddress);
    },
    shippingAddressesSameAslegalAddress: function (newVal, oldVal) {
      this.localShippingAddresses[0] = newVal
          ? this.legalAddressWatcher
        :{...new Address(),...{
          businessName: this.businessName,
          code: 'SD001'
        }};

        sortShippingAddresses(this.localShippingAddresses);
        this.$emit("update:shippingAddresses",this.localShippingAddresses);
    },
    localLegalAddress: {
      handler() {
        this.$emit("update:legalAddress", this.localLegalAddress);
        this.$emit("update:businessAddress", this.localBusinessAddress);
        this.$emit("update:shippingAddresses", this.localShippingAddresses);
        this.$emit("fieldUpdated")
      },
      deep: true,
    },
    localBusinessAddress: {
      handler() {
        this.$emit("update:businessAddress", this.localBusinessAddress);
      },
      deep: true,
    },
    localShippingAddresses: {
      handler() {
        sortShippingAddresses(this.localShippingAddresses)
        this.$emit("update:shippingAddresses", this.localShippingAddresses);
        this.$emit("fieldUpdated")
      },
      deep: true,
    },
    businessName: {
      handler() {
       this.localLegalAddress.businessName = this.businessName;
      }
    }
  },
};
</script>
