<template>

  <b-container class="px-0" fluid>
    <b-row>
      <b-col>

      </b-col>
    </b-row>

      <b-row>
        <b-col cols="12" :order="layout.site.order" v-if="layout.site.show">
          <b-form-group
              :label="$t('sds.form.site')"
              :invalid-feedback="validationError('site')"
          >
            <b-form-select
                v-model="filterForm.site"
                :state="validationState('site')"
            >
              <b-select-option
                  :value="null"
                  selected>
                {{ $t('sds.form.allSites') }}
              </b-select-option>
              <b-select-option v-for="d in siteOptions" :value="d.value" :key="d.value">
                {{ d.text }}
              </b-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" :order="layout.product.order" v-if="layout.product.show">
          <b-form-group :label="$t('sds.form.product')" :invalid-feedback="validationError('serial')">
            <b-input-group>
              <b-form-input
                  required
                  maxlength="4"
                  :placeholder="$t('sds.form.serial')"
                  v-model="filterForm.serial"
                  :state="validationState('serial')"
              />
              <b-form-input
                  required
                  maxlength="4"
                  :placeholder="$t('sds.form.color')"
                  v-model="filterForm.color"
                  :state="validationState('color')"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="12" :order="layout.customer.order" v-if="layout.customer.show">
          <b-form-group :label="$t('sds.form.customerCode')" :invalid-feedback="validationError('customerCode')">
            <b-input-group>
              <b-form-input
                  required
                  readonly
                  :placeholder="$t('sds.form.customerCode')"
                  v-model="filterForm.customerCode"
                  :state="validationState('customerCode')"
              />
              <vue-bootstrap-autocomplete
                  class="form-control p-0"
                  v-model="customerSearchString"
                  :ieCloseFix="false"
                  :data="customers"
                  :serializer="(c) => c.customerCode + ' - ' + c.businessName"
                  @hit="filterForm.customerCode = $event.customerCode"
                  :placeholder="$t('sds.form.customerCode')"
                  @input="updateCustomerDataset"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="12" :order="layout.year.order" v-if="layout.year.show">
          <b-form-group
              :invalid-feedback="validationError('year')"
          >
            <b-form-select
                v-model="filterForm.year"
                :state="validationState('year')"
            >
              <b-select-option
                  :value="null"
                  selected
                  :disabled="true">
                {{ $t('sds.form.year') }}
              </b-select-option>
              <b-select-option v-for="d in yearOptions" :value="d.value" :key="d.value">
                {{ d.text }}
              </b-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" :order="layout.orderCode.order" v-if="layout.orderCode.show">
          <b-form-group
              :invalid-feedback="validationError('orderCode')"
          >
            <b-form-group :label="$t('sds.form.orderCode')" :invalid-feedback="validationError('orderCode')">
              <b-form-input
                  required
                  :placeholder="$t('sds.form.orderCode')"
                  v-model="filterForm.orderCode"
                  :state="validationState('orderCode')"
              />
            </b-form-group>
          </b-form-group>
        </b-col>
      </b-row>

    <b-row>
      <b-col>
        <adw-button class="col px-0 col-md-6 col-12"
                    @button-click="submitSearch">
          <h3>{{ $t('sds.label.findSDS') }}</h3>
        </adw-button>
      </b-col>
    </b-row>
  </b-container>

</template>

<script>
import Validator from '@/services/validation-service/validator-service';
import {alceaDeposits} from '@/commons/deposits';
import CustomerProfileService from "@/services/api-services/customer-profile-service";
import VueBootstrapAutocomplete from '@vue-bootstrap-components/vue-bootstrap-autocomplete';
import AdwButton from "@/components/global-components/adw-button";
export default {
  name: "SdsFilter",
  components: {
    VueBootstrapAutocomplete,
    AdwButton
  },

  props: {
    sdsSearchName: String,
    filterForm: Object,
  },

  data() {
    return {
      customerSearchString: "",
      customerSelected: null,
      customers: [],
      validation: [],
      siteOptions: alceaDeposits,
      yearOptions: [],
      layout: {
        site: {
          show: false,
          order: 1
        },
        product: {
          show: false,
          order: 1
        },
        customer: {
          show: false,
          order: 1
        },
        year: {
          show: false,
          order: 1
        },
        orderCode: {
          show: false,
          order: 1
        },
      }
    };
  },

  created() {
    this.fillYearOptions();
    this.setLayout();
  },

  methods: {
    setLayout() {
      switch (this.sdsSearchName) {
        case 'searchWarehouse':
          this.layout.site.show = true;
          this.layout.site.order = 1;
          this.layout.product.show = true;
          this.layout.product.order = 2;
          this.layout.customer.show = !this.isCustomer;
          this.layout.customer.order = 3;
          this.layout.year.show = true;
          this.layout.year.order = 4;
          this.layout.orderCode.show = false;
          break;
        case 'searchProduct':
          this.layout.site.show = false;
          this.layout.product.show = true;
          this.layout.product.order = 1;
          this.layout.customer.show = false;
          this.layout.year.show = false;
          this.layout.orderCode.show = false;
          break;
        case 'searchCustomer':
          this.layout.site.show = false;
          this.layout.product.show = true;
          this.layout.product.order = 2;
          this.layout.customer.show = !this.isCustomer;
          this.layout.customer.order = 1;
          this.layout.year.show = true;
          this.layout.year.order = 3;
          this.layout.orderCode.show = false;
          break;
        case 'searchDocument':
          this.layout.orderCode.show = true;
          this.layout.orderCode.order = 1;
          this.layout.site.show = false;
          this.layout.product.show = false;
          this.layout.customer.show = false;
          this.layout.year.show = false;
      }
    },
    updateCustomerDataset () {
      this.filterForm.customerCode = null;
      if(this.customerSearchString.length > 2) {
        CustomerProfileService.autocompleteCustomerProfile(this.customerSearchString)
            .then((result) => {
              this.customers = result.data;
              this.$forceUpdate();
            })
            .catch((error) => {

            });
      }
    },
    fillYearOptions () {
      this.yearOptions = [];

      for (let y = 2016; y <= new Date().getFullYear(); y++) {
        this.yearOptions.push({value: y, text: y});
      }

      this.filterForm.year = new Date().getFullYear();
    },
    submitSearch() {
      if(this.validateCurretForm) {
        this.$emit('submit-search', this.filterForm);
      }
    },
    validationState(contextRef) {
      return this.validation.find(
          (invEl) => invEl.name.toLowerCase() === contextRef.toLowerCase()
      )
          ? false
          : null;
    },
    validationError(contextRef) {
      if (this.validation.length > 0) {
        let find =
            this.validation.find(
                (invEl) => invEl.name.toLowerCase() == contextRef.toLowerCase()
            ) || null;
        return find ? this.$i18n.t(find.error) : null;
      }
    },
    validateCurretForm() {
      this.validation = Validator.validateForm(this.sdsSearchName, this.filterForm).invalid;
      console.log(this.validation);
      if (this.validation.length > 0 ||
          Object.keys(this.filterForm).find((f) => {
            return this.validation[f] ? this.checkValidation(f) : false;
          })
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  computed: {
    isCustomer() {
      return this.$store.getters.getUserRole === "CUSTOMER";
    },
  }
}
</script>

<style scoped>

</style>