<template>
  <div class="row">
    <h3>{{ $t("titles.contacts") }}</h3>
    <div class="col-md-12">
      <div class="row justify-content-between">
        <h4 class="col">{{ $t("formCustomer.purchasingContact") }}</h4>
      </div>
      <card-contact-agent
        class="mb-2"
        :disabled="purchasingContactIsUser"
        id="purchasingContact"
        ref="purchasingContact"
        :contact="form.purchasingContact"
        :invalidErrors="
          invalidErrors.purchasingContact
            ? invalidErrors.purchasingContact.invalid
            : []
        "
        :assignmentValue="'purchasingContact'"
        :form="form"
        :billing.sync="billing"
        :msds.sync="msds"
        :testSheets.sync="testSheets"
        :transportDocument.sync="transportDocument"
      ></card-contact-agent>
      <div class="row justify-content-between">
        <h4 class="col">{{ $t("formCustomer.administrativeContact") }}</h4>
      </div>

      <card-contact-agent
        class="mb-2"
        id="administrativeContact"
        ref="administrativeContact"
        :disabled="administrativeContactIsUser"
        :contact="form.administrativeContact"
        :invalidErrors="
          invalidErrors.administrativeContact
            ? invalidErrors.administrativeContact.invalid
            : []
        "
        :assignmentValue="'administrativeContact'"
        :form="form"
        :billing.sync="billing"
        :msds.sync="msds"
        :testSheets.sync="testSheets"
        :transportDocument.sync="transportDocument"
      ></card-contact-agent>
      <div class="row justify-content-between">
        <h4 class="col" v-if="localAdditionalContacts.length > 0">
          {{ $t("formCustomer.additionalContacts") }}
        </h4>
        <b-button
          class="m-3 "
          variant="outline-secondary"
          v-if="localAdditionalContacts.length === 0"
          @click="addAddress"
        >
          <div class="row  justify-content-center align-items-center">
            <h5 class="col m-0 mx-1 px-1">
              {{ $t("formCustomer.additionalContact") }}
            </h5>
            <b-icon class="col-auto m-0" aria-hidden="true" icon="plus" font-scale="2"> </b-icon
          ></div>
        </b-button>
      </div>

      <card-contact-additional
        v-for="(adCon, index) in localAdditionalContacts"
        :key="adCon.code"
        class="mb-2"
        :ref="'additionalContacts'"
        v-bind:contact="localAdditionalContacts[index]"
        :invalidErrors="
          invalidErrors.additionalContacts &&
          invalidErrors.additionalContacts[index]
            ? invalidErrors.additionalContacts[index].invalid
            : []
        "
        @removeContact="removeContact(index)"
        :assignmentValue="'additionalContacts.' + index"
        :form="form"
        :billing.sync="billing"
        :msds.sync="msds"
        :testSheets.sync="testSheets"
        :transportDocument.sync="transportDocument"
      ></card-contact-additional>
      <b-button v-if="localAdditionalContacts.length > 0" @click="addAddress">
        {{ $t("formCustomer.additionalContact") }}</b-button
      >
      <h3>{{ $t("formCustomer.documentsRecipient") }}</h3>
      <div id="assignments" class="col-md-12 m-0 p-0">
        <form-contact-assigment
          id="billing"
          :assignment.sync="billing"
          :emailtype="'billing'"
          :form.sync="form"
          :invalidErrors="invalidErrors.invalid"
        />
        <form-contact-assigment
          id="transportDocument"
          :assignment.sync="transportDocument"
          :emailtype="'transportDocument'"
          :form.sync="form"
          :invalidErrors="invalidErrors.invalid"
        />
        <form-contact-assigment
          id="msds"
          :assignment.sync="msds"
          :emailtype="'msds'"
          :form.sync="form"
          :invalidErrors="invalidErrors.invalid"
        />
        <form-contact-assigment
          id="testSheets"
          :assignment.sync="testSheets"
          :emailtype="'testSheets'"
          :form.sync="form"
          :invalidErrors="invalidErrors.invalid"
        />
      </div>
      <b-form-group class="row">
      <b-form-checkbox
      class="col-12"
        switch
        id="tntNextRequired"
        v-model.trim="form.tntNextRequired"
        name="tntNextRequired"
      >
        {{ $t("formCustomer.tntNextRequired") }}
      </b-form-checkbox></b-form-group>
    </div>
  </div>
</template>
<script>
import CardContact from "./card-contact.vue";
import CardContactAdditional from "./card-contact-additional.vue";
import FormContactAssigment from "./form-contact-assigment.vue";
import {
  Address,
  Contact,
  sortContacts,
} from "../models/customer";
import CardContactAgent from './card-contact-agent.vue';

export default {
  components: { CardContact, FormContactAssigment, CardContactAdditional, CardContactAgent },
  name: "ContactsAgent",
  props: {
    form: Object(),
    curretStep: Object(),
    completed: Boolean(),
    invalidErrors: Array(),
    user: Object(),
  },
  data() {
    return {
      isMounted: false,
      self: "ContactsAgent",
      fields: [
        "purchasingContact",
        "administrativeContact",
        "additionalContacts",
        "billingEmails",
        "transportDocumentEmails",
        "msdsEmails",
        "testSheetsEmails",
        "tntNextRequired",
      ],
      purchasingContactIsUser: false,
      administrativeContactIsUser: false,
      administrativeContact: this.form.administrativeContact,
      purchasingContact: this.form.purchasingContact,
      localAdditionalContacts: this.form.additionalContacts || [],
      billing: {
        assigned: null,
        emails: this.form.billingEmails
      },
      transportDocument: {
        assigned: null,
        emails: this.form.transportDocumentEmails,
      },
      msds: {
        assigned: null,
        emails: this.form.msdsEmails,
      },
      testSheets: {
        assigned: null,
        emails: this.form.testSheetsEmails,
      },
    };
  },
  computed: {
    purchasingContactTelephone: {
      get() {
        return this.$refs.purchasingContact.telephone;
      },
    },
    administrativeContactTelephone: {
      get() {
        return this.$refs.administrativeContact.telephone;
      },
    },
    additionalContactsTelephones: {
      get() {
        return this.localAdditionalContacts.map((element, index) => {
          //console.log( this.$refs["additionalContacts"][index]);
          let refAddCont = this.$refs["additionalContacts"][index];
          return refAddCont.telephone;
        });
        // return this.$refs["additionalContact"].map((element, index) => {
        //   return element.telephone;})
      },
    },
  },
  methods: {
    addAddress() {
      if (this.localAdditionalContacts.length < 500) {
        let contact = new Contact(false, false, true);

        const maxIndex = this.form.contacts.reduce(
            (mi, element) => element.code && element.code.substr(0,2) === 'C' && !isNaN(parseInt(element.code)) ?  parseInt(element.code) : mi,
            0,
        );

        contact.code = "C" + ("" + (maxIndex + 1)).padStart(3, "0");
        this.localAdditionalContacts.push(contact);
        this.$emit("update:additionalContacts", this.localAdditionalContacts);
        this.$emit("update:additionalContactsTelephones", this.additionalContactsTelephones);
      }
    },
    removeContact(contact) {
      this.localAdditionalContacts.splice(contact, 1);
      this.$emit("update:additionalContacts", this.localAdditionalContacts);
    },
    getUserContact(disable) {
      this[disable] = !this[disable];
    },
    userContact(contact) {
      let isAministration = contact === "administrativeContact";
      let isPurchasing = contact ===  "purchasingContact";
      let userContact = new Contact(isAministration, isPurchasing);
      if (this.isMounted) {
        userContact = new Contact(isAministration, isPurchasing, false, false, this.user);
        userContact.role = this.form[contact].role;
      }
      return userContact;
    },

  },
  mounted() {
    this.localAdditionalContacts.forEach((element, index) => {
      let refAddCont = "additionalContacts";
      console.debug(
        "additionalContactsTelephones",
        this.$refs[refAddCont],
        this.$refs[refAddCont][index]
      );
    });
    this.isMounted = true;

    if(!this.form.purchasingContact) {
      this.form.purchasingContact = new Contact(false, true,true);
      this.form.purchasingContact.code = 'C001';
    }
    if(!this.form.administrativeContact) {
      this.form.administrativeContact = new Contact(true, false, true);
      this.form.administrativeContact.code = 'C002';
    }
    this.purchasingContactIsUser =
      Object.entries(this.form.purchasingContact).toString() ===
      Object.entries(this.userContact("purchasingContact")).toString();
    this.administrativeContactIsUser =
      Object.entries(this.form.administrativeContact).toString() ===
      Object.entries(this.userContact("administrativeContact")).toString();

    // Check if email are the same of contacts
    if(this.form.purchasingContact.email && this.form.billingEmails[0] === this.form.purchasingContact.email) {
      this.billing.assigned = 'purchasingContact';
    } else if (this.form.administrativeContact.email && this.form.billingEmails[0] === this.form.administrativeContact.email) {
      this.billing.assigned = 'administrativeContact';
    }
    if(this.form.purchasingContact.email && this.form.transportDocumentEmails[0] === this.form.purchasingContact.email) {
      this.transportDocument.assigned = 'purchasingContact';
    } else if (this.form.administrativeContact.email && this.form.transportDocumentEmails[0] === this.form.administrativeContact.email) {
      this.transportDocument.assigned = 'administrativeContact';
    }
    if(this.form.purchasingContact.email && this.form.msdsEmails[0] === this.form.purchasingContact.email) {
      this.msds.assigned = 'purchasingContact';
    } else if (this.form.administrativeContact.email && this.form.msdsEmails[0] === this.form.administrativeContact.email) {
      this.msds.assigned = 'administrativeContact';
    }
    if(this.form.purchasingContact.email && this.form.testSheetsEmails[0] === this.form.purchasingContact.email) {
      this.testSheets.assigned = 'purchasingContact';
    } else if (this.form.administrativeContact.email && this.form.testSheetsEmails[0] === this.form.administrativeContact.email) {
      this.testSheets.assigned = 'administrativeContact';
    }

  },
  watch: {
    localAdditionalContacts: {
      handler() {
        this.$emit(
          "update:localAdditionalContacts",
          sortContacts(this.localAdditionalContacts)
        );
      },
      deep: true,
    },
    "billing.assigned": {
      handler() {
        this.billing.emails[0] =
          this.billing.assigned && this.form[this.billing.assigned]
            ? this.form[this.billing.assigned].email
            : this.billing.emails[0];
      },
      deep: true,
    },
    "billing.emails": {
      handler() {
        this.$emit(
          "update:form",
          Object.assign(this.form, {
            billingEmails: this.billing.emails,
          })
        );
      },
      deep: true,
    },
    "transportDocument.assigned": {
      handler() {
        this.transportDocument.emails[0] =
          this.transportDocument.assigned &&
          this.form[this.transportDocument.assigned]
            ? this.form[this.transportDocument.assigned].email
            : this.transportDocument.emails[0];
      },
      deep: true,
    },
    "transportDocument.emails": {
      handler() {
        this.$emit(
          "update:form",
          Object.assign(this.form, {
            transportDocumentEmails: this.transportDocument.emails,
          })
        );
      },
      deep: true,
    },
    "msds.assigned": {
      handler() {
        this.msds.emails[0] =
          this.msds.assigned && this.form[this.msds.assigned]
            ? this.form[this.msds.assigned].email
            : this.msds.emails[0];
      },
      deep: true,
    },
    "msds.emails": {
      handler() {
        this.$emit(
          "update:form",
          Object.assign(this.form, {
            msdsEmails: this.msds.emails,
          })
        );
      },
      deep: true,
    },
    "testSheets.assigned": {
      handler() {
        this.testSheets.emails[0] =
          this.testSheets.assigned && this.form[this.testSheets.assigned]
            ? this.form[this.testSheets.assigned].email
            : this.testSheets.emails[0];
      },
      deep: true,
    },
    "testSheets.emails": {
      handler() {
        this.$emit(
          "update:form",
          Object.assign(this.form, {
            testSheetsEmails: this.testSheets.emails,
          })
        );
      },
      deep: true,
    },
    "form.purchasingContact": {
      handler() {
        this.$emit('fieldUpdated');
      },
      deep: true,
    },
    "form.administrativeContact": {
      handler() {
        this.$emit('fieldUpdated');
      },
      deep: true,
    },
  },
};
</script>