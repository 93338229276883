import Validator from "../services/validation-service/validator-service";
export class Customer {
  //AnagraphicsInfo fileds
  customerCode;
  businessName;
  vatNumber;
  fiscalCode;
  sdiCode;
  pec;
  //CompanyPerspective fields
  marketSegmentation1;
  marketSegmentation2;
  marketSegmentation3;
  marketSegmentation4;
  marketCompetitor1;
  marketCompetitor2;
  totalConsumption;
  alceaPotential;
  isRetailerOfMetal;
  isRetailerOfWood;
  isRetailerOfConstruction;
  isRetailerOfBodywork;
  isRetailerOfOwnership;
  retailerShopWindows;
  retailerSurface;
  tntNextRequired;
  unconditionalDiscountPct;
  //Addresses fields
  addresses = [];
  /*
  legalAddress = new Address(true);
  businessAddress = new Address(false);
  shippingAddresses = sortShippingAddresses(
    new Array({
      ...new Address(false),
      ...{ code: String() },
    })
  );
   */
  purchasingContact = new Contact();
  administrativeContact = new Contact();
  additionalContacts = sortAdditionalContacts(
    new Array(new AdditionalContact(true))
  );
  billingEmails = [];
  transportDocumentEmails = [];
  msdsEmails = [];
  testSheetsEmails = [];
  //BankInformations fields
  bankName;
  iban;

  get legalAddress() {
    return getLegalAddress(this.addresses);
  }

  set legalAddress(address) {
    let indexAddressToReplace = this.addresses.findIndex(a => a.code === address.code);
    if(indexAddressToReplace > -1)
      this.addresses[indexAddressToReplace] = address
    else
      this.addresses.push(address);
  }

  get businessAddress() {
    return getBusinessAddress(this.addresses);
  }

  set businessAddress(address) {
    if(address.code !== 'SL') {
      let indexAddressToReplace = this.addresses.findIndex(a => a.code === address.code);
      if (indexAddressToReplace > -1)
        this.addresses[indexAddressToReplace] = address
      else
        this.addresses.push(address);
    } else {
      let indexAddressToReplace = this.addresses.findIndex(a => a.code === 'SA');
    }
  }

  get shippingAddresses () {
    return sortShippingAddresses(this.addresses)
  }

  set shippingAddresses(shippingAddresses) {
    this.addresses = this.addresses.filter(a => ['SL', 'SA'].includes(a.code) && a.isShipping);
    if(shippingAddresses) {
      for (let address of shippingAddresses) {
        address.isShipping = true;
        if(address.code !== 'SL') {
          let indexAddressToReplace = this.addresses.findIndex(a => a.code === address.code);
          if (indexAddressToReplace > -1)
            this.addresses[indexAddressToReplace] = address
          else
            this.addresses.push(address);
        }
      }
    }
  }

  addNewShippingAddress () {


  }

  constructor(emptyDraft, dataCustomer, agent) {
    console.log('Customer constructor', emptyDraft, dataCustomer, agent);
    if (emptyDraft) {
      this.businessName = "";
      this.vatNumber = "";
      this.fiscalCode = null;
      this.sdiCode = null;
      this.pec = null;
      this.addresses = [new Address(true, true)];
      this.purchasingContact = new Contact();
      this.administrativeContact = new Contact();
      this.billingEmails = [];
      this.transportDocumentEmails = [];
      this.msdsEmails = [];
      this.testSheetsEmails = [];
      this.bankName = "";
      this.iban = "";

      if (agent) {
        this.marketSegmentation1 = null;
        this.marketSegmentation2 = null;
        this.marketSegmentation3 = null;
        this.marketSegmentation4 = null;
        this.marketCompetitor1 = null;
        this.marketCompetitor2 = null;
        this.totalConsumption = 0;
        this.alceaPotential = 0;
        this.isRetailerOfMetal = false;
        this.isRetailerOfWood = false;
        this.isRetailerOfConstruction = false;
        this.isRetailerOfBodywork = false;
        this.isRetailerOfOwnership = false;
        this.retailerShopWindows = 0;
        this.retailerSurface = 0;
        this.tntNextRequired = false;
        this.contacts = sortAdditionalContacts(new Array());
      }
    } else {
      if (dataCustomer) {

        this.businessName = dataCustomer.businessName;
        this.vatNumber = dataCustomer.vatNumber;
        this.fiscalCode = dataCustomer.fiscalCode;
        this.sdiCode = dataCustomer.sdiCode;
        this.pec = dataCustomer.pec;
        this.addresses = dataCustomer.addresses || [new Address(true, true)];
        this.contacts = sortAdditionalContacts(dataCustomer.contacts);
        this.billingEmails = dataCustomer.billingEmails;
        this.transportDocumentEmails = dataCustomer.transportDocumentEmails;
        this.msdsEmails = dataCustomer.msdsEmails;
        this.testSheetsEmails = dataCustomer.testSheetsEmails;
        this.bankName = dataCustomer.bankName;
        this.iban = dataCustomer.iban;

        if (agent) {
          this.marketSegmentation1 = dataCustomer.marketSegmentation1;
          this.marketSegmentation2 = dataCustomer.marketSegmentation2;
          this.marketSegmentation3 = dataCustomer.marketSegmentation3;
          this.marketSegmentation4 = dataCustomer.marketSegmentation4;
          this.marketCompetitor1 = dataCustomer.marketCompetitor1;
          this.marketCompetitor2 = dataCustomer.marketCompetitor2;
          this.totalConsumption = dataCustomer.totalConsumption;
          this.alceaPotential = dataCustomer.alceaPotential;
          this.isRetailerOfMetal = dataCustomer.isRetailerOfMetal;
          this.isRetailerOfWood = dataCustomer.isRetailerOfWood;
          this.isRetailerOfConstruction = dataCustomer.isRetailerOfConstruction;
          this.isRetailerOfBodywork = dataCustomer.isRetailerOfBodywork;
          this.isRetailerOfOwnership = dataCustomer.isRetailerOfOwnership;
          this.retailerShopWindows = dataCustomer.retailerShopWindows;
          this.retailerSurface = dataCustomer.retailerSurface;
          this.tntNextRequired = dataCustomer.tntNextRequired;
          this.additionalContacts = sortAdditionalContacts(
              dataCustomer.contacts.map((element) => {
                return new AdditionalContact(false, element);
              })
          );
        }
      }
    }
    console.log('Customer constructor [end]', this);
  }
}

export class Address {
  code;
  businessName;
  streetAddress1;
  streetAddress2;
  country;
  city;
  zipOrPostalCode;
  province;
  notes;
  isDefault;
  isShipping;
  constructor(isLegal, emptyDraft, dataAddress, formToPostContact) {
    console.log('Address constructor', isLegal, emptyDraft, dataAddress, formToPostContact);
    if (emptyDraft) {
      this.code = isLegal ? 'SL' : '';
      this.businessName= "";
      this.streetAddress1 = "";
      this.streetAddress2 = "";
      this.country = "";
      this.city = "";
      this.zipOrPostalCode = "";
      this.province = "";
      this.notes = "";
      this.isDefault = false;
      this.isShipping = true;
    } else {
      if (dataAddress) {
        this.code = dataAddress.code;
        this.businessName = dataAddress.businessName;
        this.streetAddress1 = dataAddress.streetAddress1;
        this.streetAddress2 = dataAddress.streetAddress2;
        this.country = dataAddress.country;
        this.city = dataAddress.city;
        this.zipOrPostalCode = dataAddress.zipOrPostalCode;
        this.province = dataAddress.province;
        this.notes = dataAddress.notes;
        this.isDefault = dataAddress.isDefault
        this.isShipping = dataAddress.isShipping
      } else {
        if (formToPostContact) {
          this.code = Validator.valid(formToPostContact.code) ? formToPostContact.code : null;
          this.streetAddress1 = Validator.valid(
            formToPostContact.streetAddress1
          )
            ? formToPostContact.streetAddress1
            : null;
          this.streetAddress2 = Validator.valid(
            formToPostContact.streetAddress2
          )
            ? formToPostContact.streetAddress2
            : null;
          this.country = Validator.valid(formToPostContact.country)
            ? formToPostContact.country
            : null;
          this.city = Validator.valid(formToPostContact.city)
            ? formToPostContact.city
            : null;
          this.zipOrPostalCode = Validator.valid(
            formToPostContact.zipOrPostalCode
          )
            ? formToPostContact.zipOrPostalCode
            : null;
          this.notes = Validator.valid(formToPostContact.notes)
            ? formToPostContact.notes
            : null;
          this.businessName = Validator.valid(formToPostContact.businessName)
            ? formToPostContact.businessName
            : null;
          this.province = Validator.valid(formToPostContact.province)
            ? formToPostContact.province
            : null;
        }
      }
    }

    console.log('Address constructor end', this);
  }
  equal(address) {
    return (
      address &&
      this.code === address.code &&
      //this.businessName == address.businessAddress &&
      this.streetAddress1 === address.streetAddress1 &&
      this.streetAddress2 === address.streetAddress2 &&
      this.country === address.country &&
      this.city === address.city &&
      this.zipOrPostalCode === address.zipOrPostalCode &&
      this.province === address.province &&
      this.note === address.note
    );
  }
}

export function getLegalAddress (arrayAddresses) {
  console.log('getLegalAddress', arrayAddresses)
  if(!arrayAddresses)
    return new Address(true, true, false);

  const address = arrayAddresses.find(a => "SL" === a.code);

  if(address)
    return new Address(true, false, false, address);

  return new Address(true, true);

}
export function getBusinessAddress (arrayAddresses) {
  console.log('getBusinessAddress', arrayAddresses)
  if(!arrayAddresses) {
    return getLegalAddress(arrayAddresses);
  }

  const address = arrayAddresses.find(a => "SA" === a.code);

  if(address)
    return new Address(false, false, false, address);
  else
    return getLegalAddress(arrayAddresses);
}
export function sortShippingAddresses(arrayAddresses) {
  console.log('sortShippingAddresses', arrayAddresses)
  if(!arrayAddresses) {
    return [getLegalAddress(arrayAddresses)];
  }

  let maxIndex = arrayAddresses.reduce((max, ca) => {
    return ca.isShipping && ca.code && ca.code.substr(0,2) === 'SD' && !isNaN(parseInt(ca.code.slice(-3))) ?  parseInt(ca.code.slice(-3)) : max
  }, 0);

  let shippingAddresses = arrayAddresses.filter(a => a.isShipping).map((element, index) => {
    return new Address(false,false, false, Object.assign(element, {
      code: Validator.valid(element.code)
          ? element.code
          : "SD" + ("" + (++maxIndex)).padStart(3, "0")
    }));
  });
  return shippingAddresses.sort(function (a, b) {
    return a.code - b.code;
  });
}
export function sortAdditionalContacts(arrayContacts) {
  let contacts = arrayContacts.map((element, index) => {
    element.code = Validator.valid(element.code)
      ? element.code
        : "C" + ("" + (index + 1)).padStart(3, "0");
    return element;
  });
  return contacts.sort(function (a, b) {
    return a.code - b.code;
  });
}
export class Contact {
  gender;
  firstName;
  lastName;
  role;
  email;
  phoneNumber;
  constructor(emptyDraft, dataContact, userContact, formToPostContact) {
    if (emptyDraft) {
      this.gender = "";
      this.firstName = "";
      this.lastName = "";
      this.role = "";
      this.email = "";
      this.phoneNumber = "";
    } else {
      if (dataContact) {
        this.gender = dataContact.gender;
        this.firstName = dataContact.firstName;
        this.lastName = dataContact.lastName;
        this.role = dataContact.role;
        this.email = dataContact.email;
        this.phoneNumber = dataContact.phoneNumber;
      } else if (userContact) {
        this.gender = userContact.gender;
        this.firstName = userContact.firstname;
        this.lastName = userContact.lastname;
        this.email = userContact.email;
        this.phoneNumber = userContact.telephone;
      } else if (formToPostContact) {
        this.gender = Validator.valid(formToPostContact.gender)
          ? formToPostContact.gender
          : null;
        this.firstName = Validator.valid(formToPostContact.gender)
          ? formToPostContact.firstName
          : null;
        this.lastName = Validator.valid(formToPostContact.gender)
          ? formToPostContact.lastName
          : null;
        this.role = Validator.valid(formToPostContact.gender)
          ? formToPostContact.role
          : null;
        this.email = Validator.valid(formToPostContact.gender)
          ? formToPostContact.email
          : null;
        this.phoneNumber = Validator.valid(formToPostContact.gender)
          ? formToPostContact.phoneNumber
          : null;
      }
    }
  }
}
/*"administrativeContact.department: non deve essere null;  
 purchasingContact.department: non deve essere null"*/
export class AdditionalContact {
  code;
  gender;
  firstName;
  lastName;
  role;
  email;
  phoneNumber;
  department;
  constructor(emptyDraft, dataContact, userContact, formToPostContact) {
    if (emptyDraft) {
      this.code = "";
      this.gender = null;
      this.firstName = "";
      this.lastName = "";
      this.role = null;
      this.email = "";
      this.phoneNumber = null;
      this.department = null;
    } else {
      if (dataContact) {
        this.code = dataContact.code;
        this.gender = dataContact.gender;
        this.firstName = dataContact.firstName;
        this.lastName = dataContact.lastName;
        this.role = dataContact.role;
        this.email = dataContact.email;
        this.phoneNumber = dataContact.phoneNumber;
        this.department = dataContact.department;
      } else if (userContact) {
        this.code = userContact.code;
        this.gender = userContact.gender;
        this.firstName = userContact.firstname;
        this.lastName = userContact.lastname;
        this.department = userContact.department;
        this.role = userContact.businessRole;
        //this.role=null
        this.email = userContact.email;
        this.phoneNumber = userContact.telephone;
      } else if (formToPostContact) {
        this.code = Validator.valid(formToPostContact.code)
          ? formToPostContact.code
          : null;
        this.gender = Validator.valid(formToPostContact.gender)
          ? formToPostContact.gender
          : null;
        this.firstName = Validator.valid(formToPostContact.firstName)
          ? formToPostContact.firstName
          : null;
        this.lastName = Validator.valid(formToPostContact.lastName)
          ? formToPostContact.lastName
          : null;
        this.role = Validator.valid(formToPostContact.role)
          ? formToPostContact.role
          : null;
        this.email = Validator.valid(formToPostContact.email)
          ? formToPostContact.email
          : null;
        this.phoneNumber = Validator.valid(formToPostContact.phoneNumber)
          ? formToPostContact.phoneNumber
          : null;

        this.department = Validator.valid(formToPostContact.department)
          ? formToPostContact.department
          : null;
      }
    }
  }
}
export function getCustomerForm(emptyDraft, dataCustomer, agent) {
  let form;
  if (emptyDraft) {
    form = {
      businessName: "",
      vatNumber: "",
      fiscalCode: null,
      sdiCode: null,
      pec: null,
      addresses: [new Address(true)],
      /*
      legalAddress: new Address(true),
      businessAddress: new Address(false),
      shippingAddresses: sortShippingAddresses(
        new Array({
          ...new Address(false),
          ...{ code: String() },
        })
      ),
       */
      purchasingContact: new Contact(),
      administrativeContact: new Contact(),
      billingEmails: [],
      transportDocumentEmails: [],
      msdsEmails: [],
      testSheetsEmails: [],
      bankName: "",
      iban: "",
    };
    if (agent) {
      form = {
        ...form,
        ...{
          marketSegmentation1: null,
          marketSegmentation2: null,
          marketSegmentation3: null,
          marketSegmentation4: null,
          marketCompetitor1: null,
          marketCompetitor2: null,
          totalConsumption: 0,
          alceaPotential: 0,
          isRetailerOfMetal: false,
          isRetailerOfWood: false,
          isRetailerOfConstruction: false,
          isRetailerOfBodywork: false,
          isRetailerOfOwnership: false,
          retailerShopWindows: 0,
          retailerSurface: 0,
          tntNextRequired: false,
          contacts: sortAdditionalContacts(new Array()),
        },
      };
    }
  } else {
    if (dataCustomer) {
      form = {
        businessName: dataCustomer.businessName,
        vatNumber: dataCustomer.vatNumber,
        fiscalCode: dataCustomer.fiscalCode,
        sdiCode: dataCustomer.sdiCode,
        pec: dataCustomer.pec,
        addresses: dataCustomer.addresses,
        /*
        legalAddress: getLegalAddress(dataCustomer.addresses),
        businessAddress: getBusinessAddress(dataCustomer.addresses),
        shippingAddresses: sortShippingAddresses(
          dataCustomer.addresses
        ),
         */
        contacts: sortAdditionalContacts(dataCustomer.contacts),
        billingEmails: dataCustomer.billingEmails,
        transportDocumentEmails: dataCustomer.transportDocumentEmails,
        msdsEmails: dataCustomer.msdsEmails,
        testSheetsEmails: dataCustomer.testSheetsEmails,
        bankName: dataCustomer.bankName,
        iban: dataCustomer.iban,
      };
      if (agent) {
        form = {
          ...form,
          ...{
            marketSegmentation1: dataCustomer.marketSegmentation1,
            marketSegmentation2: dataCustomer.marketSegmentation2,
            marketSegmentation3: dataCustomer.marketSegmentation3,
            marketSegmentation4: dataCustomer.marketSegmentation4,
            marketCompetitor1: dataCustomer.marketCompetitor1,
            marketCompetitor2: dataCustomer.marketCompetitor2,
            totalConsumption: dataCustomer.totalConsumption,
            alceaPotential: dataCustomer.alceaPotential,
            isRetailerOfMetal: dataCustomer.isRetailerOfMetal,
            isRetailerOfWood: dataCustomer.isRetailerOfWood,
            isRetailerOfConstruction: dataCustomer.isRetailerOfConstruction,
            isRetailerOfBodywork: dataCustomer.isRetailerOfBodywork,
            isRetailerOfOwnership: dataCustomer.isRetailerOfOwnership,
            retailerShopWindows: dataCustomer.retailerShopWindows,
            retailerSurface: dataCustomer.retailerSurface,
            tntNextRequired: dataCustomer.tntNextRequired,
            additionalContacts: sortAdditionalContacts(
              dataCustomer.contacts.map((element) => {
                return new AdditionalContact(false, element);
              })
            ),
          },
        };
      }
    }
  }
  return form;
}

export function getCustomerCompleteForm(draft, agent) {
  let form;
  if (draft) {
    form = {
      businessName: Validator.valid(draft.businessName)
        ? draft.businessName
        : null,
      vatNumber: Validator.valid(draft.vatNumber) ? draft.vatNumber : null,
      fiscalCode: Validator.valid(draft.fiscalCode)
        ? draft.fiscalCode
        : null,
      sdiCode: Validator.valid(draft.sdiCode) ? draft.sdiCode : null,
      pec: Validator.valid(draft.pec) ? draft.pec : null,
      addresses: draft.addresses,
      /*
      legalAddress: getLegalAddress(draft.addresses),
      businessAddress: getBusinessAddress(draft.addresses),
      shippingAddresses: sortShippingAddresses(
          draft.addresses
      ),
       */
      purchasingContact: new Contact(
        false,
        null,
        null,
        draft.purchasingContact
      ),
      administrativeContact: new Contact(
        false,
        null,
        null,
        draft.administrativeContact
      ),
      billingEmails: Validator.valid(draft.billingEmails)
        ? draft.billingEmails
        : [],
      transportDocumentEmails: Validator.valid(draft.transportDocumentEmails)
        ? draft.transportDocumentEmails
        : [],
      msdsEmails: Validator.valid(draft.msdsEmails) ? draft.msdsEmails : [],
      testSheetsEmails: Validator.valid(draft.testSheetsEmails)
        ? draft.testSheetsEmails
        : [],
      bankName: Validator.valid(draft.bankName) ? draft.bankName : null,
      iban: Validator.valid(draft.iban) ? draft.iban : null,
    };
    if (agent) {
      form = {
        ...form,
        ...{
          marketSegmentation1: Validator.valid(draft.marketSegmentation1)
            ? draft.marketSegmentation1
            : null,
          marketSegmentation2: Validator.valid(draft.marketSegmentation2)
            ? draft.marketSegmentation2
            : null,
          marketSegmentation3: Validator.valid(draft.marketSegmentation3)
            ? draft.marketSegmentation3
            : null,
          marketSegmentation4: Validator.valid(draft.marketSegmentation4)
            ? draft.marketSegmentation4
            : null,
          marketCompetitor1: Validator.valid(draft.marketCompetitor1)
            ? draft.marketCompetitor1
            : null,
          marketCompetitor2: Validator.valid(draft.marketCompetitor2)
            ? draft.marketCompetitor2
            : null,
          totalConsumption: Validator.valid(draft.totalConsumption)
            ? draft.totalConsumption
            : null,
          alceaPotential: Validator.valid(draft.alceaPotential)
            ? draft.alceaPotential
            : null,
          isRetailerOfMetal: Validator.valid(draft.isRetailerOfMetal)
            ? draft.isRetailerOfMetal
            : null,
          isRetailerOfWood: Validator.valid(draft.isRetailerOfWood)
            ? draft.isRetailerOfWood
            : null,
          isRetailerOfConstruction: Validator.valid(
            draft.isRetailerOfConstruction
          )
            ? draft.isRetailerOfConstruction
            : null,
          isRetailerOfBodywork: Validator.valid(draft.isRetailerOfBodywork)
            ? draft.isRetailerOfBodywork
            : null,
          isRetailerOfOwnership: Validator.valid(draft.isRetailerOfOwnership)
            ? draft.isRetailerOfOwnership
            : null,
          retailerShopWindows: Validator.valid(draft.retailerShopWindows)
            ? draft.retailerShopWindows
            : null,
          retailerSurface: Validator.valid(draft.retailerSurface)
            ? draft.retailerSurface
            : null,
          tntNextRequired: Validator.valid(draft.tntNextRequired)
            ? draft.tntNextRequired
            : null,
          additionalContacts: draft.additionalContacts.map((element) => {
            return new AdditionalContact(false, element);
          }),
        },
      };
    }
  }
  return form;
}
