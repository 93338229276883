export const formUser = {
  repeatPwd: "Powtórz hasło",
  subscribeToNewsletter: "I want to be updated on Alcea news via newsletter ",
  textLinkNewsletterGdpr: "art. 13 of European Regulation n° 679/2016",
  authorizeCommercial:
    "I authorize the use of my personal data for market research",
  notificationMail:
    "You haven't enabled email login notification yet!<br/>" +
    "Do you want to enabled now?<br/><br/>" +
    "<b>  Note: You can change this setting from your profile at any time</b>",
};
