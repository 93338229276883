var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-12"},[_c('h3',{staticClass:"text-uppercase"},[_vm._v(_vm._s(_vm.$t("titles.anagraphicInfo")))]),_c('div',{staticClass:"border-top"},[_c('b-form-group',{staticClass:"inline-form-group",attrs:{"invalid-feedback":_vm.validationError('businessName')}},[_c('label',{attrs:{"for":"businessName"}},[_vm._v(" "+_vm._s(_vm.$t("formUser.businessName")))]),_c('b-form-input',{attrs:{"type":"text","id":"name","name":"name","placeholder":_vm.$t('formUser.businessName') + ' *',"state":_vm.validationState('businessName')},on:{"change":function($event){return _vm.$emit('fieldUpdated')}},model:{value:(_vm.form.businessName),callback:function ($$v) {_vm.$set(_vm.form, "businessName", $$v)},expression:"form.businessName"}})],1),_c('b-form-group',{attrs:{"invalid-feedback":_vm.validationError('vatNumber'),"is-valid":_vm.validationState('vatumber')}},[_c('label',{staticClass:"w-100",attrs:{"for":"vatNumber"}},[_vm._v(" "+_vm._s(_vm.$t("formCustomer.vatNumber"))+" "),(_vm.edit)?_c('span',{staticClass:"text-md-right w-auto ml-md-auto"},[_vm._v(" - "),_c('b-icon-info-circle',{attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.$t("instructions.formCustomer.vatNumber")))],1):_vm._e()]),_c('b-input-group',{class:_vm.validationState('vatNumber') ? '' : 'is-invalid'},[_c('b-form-select',{ref:"country",staticClass:"form-select col-md-2",attrs:{"disabled":_vm.edit,"state":_vm.validationState('vatNumber')},on:{"input":function($event){_vm.vatNumber.number
                ? (_vm.form.vatNumber = _vm.vatNumber.prefix + _vm.vatNumber.number)
                : (_vm.form.vatNumber = '')},"change":function($event){_vm.form.businessAddress.country =
                _vm.vatNumber.prefix; _vm.ifItalian(_vm.vatNumber.prefix);
                _vm.$emit('fieldUpdated')}},model:{value:(_vm.vatNumber.prefix),callback:function ($$v) {_vm.$set(_vm.vatNumber, "prefix", $$v)},expression:"vatNumber.prefix"}},[_c('b-select-option',{attrs:{"selected":"","value":null || ''}},[_vm._v(" "+_vm._s(_vm.$t("formCustomer.noPrefix"))+" ")]),_vm._l((_vm.countries),function(country){return _c('b-select-option',{key:country.key,attrs:{"value":country.value}},[_vm._v(" "+_vm._s(country.label)+" ")])})],2),_c('b-form-input',{attrs:{"disabled":_vm.edit,"type":"text","id":"vatNumber","name":"vatNumber","placeholder":_vm.$t('formCustomer.vatNumber') + ' *',"state":_vm.validationState('vatNumber')},on:{"input":function($event){_vm.vatNumber.number
                ? (_vm.form.vatNumber = _vm.vatNumber.prefix + _vm.vatNumber.number)
                : (_vm.form.vatNumber = '')},"change":function($event){return _vm.$emit('fieldUpdated')}},model:{value:(_vm.vatNumber.number),callback:function ($$v) {_vm.$set(_vm.vatNumber, "number", $$v)},expression:"vatNumber.number"}})],1)],1),_c('b-form-group',{staticClass:"inline-form-group",attrs:{"invalid-feedback":_vm.validationError('fiscalCode')}},[_c('label',{attrs:{"for":"fiscalCode"}},[_vm._v(" "+_vm._s(_vm.$t("formCustomer.fiscalCode")))]),_c('b-form-input',{attrs:{"type":"text","id":"name","name":"name","placeholder":_vm.$t('formCustomer.fiscalCode'),"state":_vm.validationState('fiscalCode')},on:{"change":function($event){return _vm.$emit('fieldUpdated')}},model:{value:(_vm.form.fiscalCode),callback:function ($$v) {_vm.$set(_vm.form, "fiscalCode", $$v)},expression:"form.fiscalCode"}})],1),_c('b-form-group',{attrs:{"invalid-feedback":_vm.validationError('sdiCode')}},[_c('label',{attrs:{"for":"sdiCode"}},[_vm._v(" "+_vm._s(_vm.$t("formCustomer.sdiCode")))]),_c('b-form-input',{attrs:{"type":"text","id":"sdiCode","name":"sdiCode","placeholder":_vm.form.businessAddress.country == 'IT'
              ? _vm.$t('formCustomer.sdiCode') + ' *'
              : _vm.$t('formCustomer.sdiCode'),"state":_vm.validationState('sdiCode')},on:{"change":function($event){return _vm.$emit('fieldUpdated')}},model:{value:(_vm.form.sdiCode),callback:function ($$v) {_vm.$set(_vm.form, "sdiCode", $$v)},expression:"form.sdiCode"}})],1),_c('b-form-group',{attrs:{"invalid-feedback":_vm.validationError('pec')}},[_c('label',{attrs:{"for":"Pec"}},[_vm._v(" "+_vm._s(_vm.$t("formCustomer.pec"))+" ")]),_c('b-form-input',{attrs:{"type":"text","id":"Pec","name":"Pec","placeholder":_vm.form.businessAddress.country == 'IT'
              ? _vm.$t('formCustomer.pec') + ' *'
              : _vm.$t('formCustomer.pec'),"state":_vm.validationState('pec')},on:{"change":function($event){return _vm.$emit('fieldUpdated')}},model:{value:(_vm.form.pec),callback:function ($$v) {_vm.$set(_vm.form, "pec", $$v)},expression:"form.pec"}})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }