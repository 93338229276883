import { labels } from "./labels/labels";
import { errors } from "./labels/errors";
import { actions } from "./labels/actions";
import { titles } from "./labels/titles";
import { formUser } from "./labels/form-user";
import { formCustomer } from "./labels/form-customer";
import { departments } from "./labels/departments";
import { alert } from "./labels/alert";
import { roles } from "./labels/roles";
import { interests } from "./labels/interests";
import { types } from "./labels/types";
import { requestStatus } from "./labels/requestStatus";
import { tooltips } from "./labels/tooltips";
import { invitation } from "./labels/invitation";
import { retailer } from "./labels/retailer";
import { product } from "./labels/product";
import { languages } from "./labels/languages";
import { subtitles } from "./labels/subtitles";
import { instructions } from "./labels/instructions";
import { formCheckout } from "./labels/form-checkout";
import { offerInfo } from "./labels/offerInfo";
import { orderInfo } from "./labels/orderInfo";
import { productsSearch } from "./labels/products-search";
import { iubenda } from "./links/iubenda";
import { breadcrumb } from "./labels/breadcrumb";
import { formContact } from "./labels/form-contact";
import { faq } from "./labels/faq";
import { sds } from "./labels/sds";

export const pl = {
  labels,
  errors,
  actions,
  titles,
  formUser,
  formContact,
  formCustomer,
  departments,
  alert,
  roles,
  interests,
  types,
  requestStatus,
  tooltips,
  invitation,
  retailer,
  product,
  languages,
  subtitles,
  instructions,
  formCheckout,
  offerInfo,
  orderInfo,
  productsSearch,
  iubenda,
  breadcrumb,
  faq,
  sds,
};
